import { ALLOW_SERVICE, DISALLOW_SERVICE } from './mutation-types'

export const allowService = ({ commit }, service) => {
  commit(ALLOW_SERVICE, service)
}

export const disallowService = ({ commit }, service) => {
  commit(DISALLOW_SERVICE, service)
}

export default {
  allowService,
  disallowService
}
