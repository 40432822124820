import {
  SET_FIRST_UTM_TOUCHPOINT,
  SET_LAST_UTM_TOUCHPOINT
} from './mutation-types'

export default {
  [SET_FIRST_UTM_TOUCHPOINT]: (state, utm) => {
    state.utm.first_touchpoint = { ...state.utm.first_touchpoint, ...utm }
  },
  [SET_LAST_UTM_TOUCHPOINT]: (state, utm) => {
    state.utm.last_touchpoint = { ...state.utm.last_touchpoint, ...utm }
  }
}
