import { searchVehiclesByCompany } from '@/app/requests/vehicles'
import {
  SET_RAW_VEHICLE_DATA,
  SET_PAGINATION_DATA,
  APPEND_RAW_VEHICLE_DATA,
  SET_PAGE,
  RESET_STATE
} from './mutation-types'

export const fetchVehicles = ({ commit, rootGetters }, { params, options }) => {
  return searchVehiclesByCompany(
    rootGetters['core/companyId'],
    params,
    options
  ).then(res => {
    commit(SET_RAW_VEHICLE_DATA, res.docs)
    commit(SET_PAGINATION_DATA, {
      total: res.total,
      page: res.page,
      pages: res.pages,
      offset: res.offset,
      limit: res.limit
    })
    return res
  })
}

// used for pagination fetches (load more...)
export const fetchFurtherVehicles = (
  { commit, rootGetters },
  { params, options }
) => {
  return searchVehiclesByCompany(
    rootGetters['core/companyId'],
    params,
    options
  ).then(res => {
    commit(APPEND_RAW_VEHICLE_DATA, res.docs)
    commit(SET_PAGINATION_DATA, {
      total: res.total,
      page: res.page,
      pages: res.pages,
      offset: res.offset,
      limit: res.limit
    })
    return res
  })
}

export const setPage = ({ commit }, page) => {
  commit(SET_PAGE, page)
}

export const resetState = ({ commit }) => {
  commit(RESET_STATE)
}

export default {
  fetchVehicles,
  fetchFurtherVehicles,
  setPage,
  resetState
}
