import Vue from 'vue'
import App from './App.vue'
import { createI18n } from './i18n'
import Meta from 'vue-meta'
// import Amui from './../../ui'
import { createRouter } from './router'
import IconSet from './../shared/themes/ENV.VUE_APP_THEME/icons/index.js'
/*
import {
  AmSearchFormCompositionBudget,
  AmSearchFormCompositionVehicleCategory,
  AmSearchFormCompositionVehicleModel
} from './../shared/components/index.js'
// TODO-VDP
*/
import './scss/main.scss'
import { createIdGenerator } from './../../ui/utils/auto-increment'

Vue.config.productionTip = false

Vue.use(Meta)
// Vue.use(Amui) // TODO-VDP

/*
// shared components
Vue.component(AmSearchFormCompositionBudget.name, AmSearchFormCompositionBudget)
Vue.component(
  AmSearchFormCompositionVehicleCategory.name,
  AmSearchFormCompositionVehicleCategory
)
Vue.component(
  AmSearchFormCompositionVehicleModel.name,
  AmSearchFormCompositionVehicleModel
)
// TODO-VDP
*/

Vue.use(IconSet)

export function createApp(config, { store }) {
  const i18n = createI18n()

  Vue.prototype.$am = {
    id: createIdGenerator()
  }

  const isAdMode = store.state.core.rawVehicleMarketCompanyData.isAdMode
  const hasModelConfiguratorPage =
    store.state.core.rawVehicleMarketCompanyData.hasModelConfiguratorPage
  const hasTestDrivePage =
    store.state.core.rawVehicleMarketCompanyData.hasTestDrivePage

  const routePaths = store.getters['core/routes']

  const routes = [
    {
      path: routePaths.app,
      name: 'app',
      component: () => import('@/app/views/search-hybrid/search-hybrid.vue')
    },
    {
      path: routePaths.detail,
      name: 'detail',
      component: () =>
        import('@/app/views/vehicle-detail-page/vehicle-detail-page.vue')
    },
    {
      path: routePaths.favorites,
      name: 'favorites',
      component: () => import('@/app/views/favorites/favorites.vue')
    },
    {
      path: routePaths.tradeIn,
      name: 'trade-in',
      component: () => import('@/app/views/trade-in/trade-in.vue')
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import('@/app/views/error-404/error-404.vue')
    }
  ].filter(routeConfig => {
    return (
      !isAdMode ||
      routeConfig.name === 'detail' ||
      routeConfig.name === 'not-found'
    )
  })

  if (hasModelConfiguratorPage) {
    routes.push({
      path: routePaths.modelConfigurator,
      name: 'model-configurator',
      component: () =>
        import('@/app/views/model-configurator/model-configurator.vue')
    })
  }

  if (hasTestDrivePage) {
    routes.push({
      path: routePaths.testDrive,
      name: 'test-drive',
      component: () => import('@/app/views/test-drive/test-drive.vue')
    })
  }

  const router = createRouter({
    basePath: config.basePath,
    routes,
    mode: config.routerMode
  })

  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  })

  return { app, router, store, i18n }
}
