var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',[_vm._v("is loading...")]):_c('div',{class:{
    'am-body': true,
    'am-body--layout': this.appMode !== 'widget'
  },style:(_vm.cssVars),attrs:{"id":"app"}},[(_vm.appMode !== 'widget')?_c('am-header',{attrs:{"title":_vm.appTitle,"logo-source":_vm.logoSource,"brand-sources":_vm.brandSources,"navigation":_vm.primaryNavigation,"home-link":_vm.homeLink,"is-ad-mode":_vm.isAdMode,"favorite-vehicles-count":_vm.favoriteVehiclesCount},on:{"change-route":_vm.onChangeRoute}}):_vm._e(),_c('main',[_c('router-view')],1),(_vm.appMode !== 'widget')?_c('am-footer',{attrs:{"social-media-links":_vm.socialMediaLinks,"meta-navigation":_vm.metaNavigation}}):_vm._e(),(
      _vm.appMode !== 'widget' &&
        _vm.userlikeChatWidget &&
        _vm.hasAcceptedCookiesForUserlike === false
    )?_c('am-userlike-chat-button',{attrs:{"aria-label":_vm.$t('userlike.messenger.buttonLabel')},nativeOn:{"click":function($event){return _vm.openDataPrivacyUserlikeDialog.apply(null, arguments)}}}):_vm._e(),_c('amui-modal',{on:{"close":_vm.closeDataPrivacyUserlikeDialog},model:{value:(_vm.hasOpenDataPrivacyUserlikeDialog),callback:function ($$v) {_vm.hasOpenDataPrivacyUserlikeDialog=$$v},expression:"hasOpenDataPrivacyUserlikeDialog"}},[_c('am-userlike-privacy-dialog',{on:{"confirm":_vm.allowUserlikeService,"cookie-settings":_vm.openCookieSettings,"close":_vm.closeDataPrivacyUserlikeDialog}})],1),_c('am-notifications')],1)}
var staticRenderFns = []

export { render, staticRenderFns }