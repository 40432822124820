export function createIdGenerator() {
  let incrementedNumber = 0

  return {
    get() {
      return ++incrementedNumber
    }
  }
}

export default {
  createIdGenerator
}
