import { theme } from './../shared/themes/ENV.VUE_APP_THEME'

export default {
  token: null,
  apiBaseUrl: 'https://marketplace-connect.automedia-demo.de',
  theme: theme,
  basePath: '/',
  vehicleClass: [],
  canonicalBaseUrl: null,
  routerMode: 'history'
}
