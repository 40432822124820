import { convertToFloat, convertToCents } from './../utils/converter'
import { format, differenceInDays, parseISO } from 'date-fns'

const getDiscountToConsumerPriceInPercent = normalizedPriceData => {
  let discountInPercent = null

  if (
    normalizedPriceData.amPreviousConsumerPriceGross !== undefined &&
    normalizedPriceData.consumerPriceGross !== undefined
  ) {
    discountInPercent = Math.round(
      (1 -
        normalizedPriceData.consumerPriceGross /
          normalizedPriceData.amPreviousConsumerPriceGross) *
        100
    )
  }

  return discountInPercent
}

const getDiscountToListPriceInPercent = normalizedPriceData => {
  let discountInPercent = null

  if (
    normalizedPriceData.amListPriceGross !== undefined &&
    normalizedPriceData.consumerPriceGross !== undefined
  ) {
    discountInPercent = Math.round(
      (1 -
        normalizedPriceData.consumerPriceGross /
          normalizedPriceData.amListPriceGross) *
        100
    )
  }

  return discountInPercent
}

const getDaysSinceLastConsumerPriceChange = normalizedPriceData => {
  let days = null

  if (normalizedPriceData.amPreviousConsumerPriceDate !== undefined) {
    days = differenceInDays(
      new Date(format(new Date(), 'yyyy-MM-dd')),
      new Date(
        parseISO(
          format(
            parseISO(normalizedPriceData.amPreviousConsumerPriceDate),
            'yyyy-MM-dd'
          )
        )
      )
    )
  }

  return days
}

/**
 * @param {*} normalizedPriceData
 * @param {*} vehicleType
 * @param {*} maxDaysSinceLastPriceChange
 * @param {*} minPriceDifferenceInPercent
 * @returns
 */
const isAllowedToDisplayPriceDiscounts = (
  normalizedPriceData,
  vehicleType,
  maxDaysSinceLastPriceChange,
  minPriceDifferenceInPercent
) => {
  let isAllowedToDisplay = false
  let discountInPercent

  if (vehicleType.toLowerCase() === 'g') {
    discountInPercent = getDiscountToConsumerPriceInPercent(normalizedPriceData)
  } else {
    discountInPercent = getDiscountToListPriceInPercent(normalizedPriceData)
  }

  if (
    discountInPercent !== null &&
    discountInPercent >= minPriceDifferenceInPercent
  ) {
    if (vehicleType.toLowerCase() === 'g') {
      const _differenceInDays = getDaysSinceLastConsumerPriceChange(
        normalizedPriceData
      )

      if (
        _differenceInDays !== null &&
        _differenceInDays <= maxDaysSinceLastPriceChange
      ) {
        isAllowedToDisplay = true
      }
    } else {
      isAllowedToDisplay = true
    }
  }

  return isAllowedToDisplay
}

/**
 * this method will process the incoming mobile data format in a way that it is better useable in our application.
 * it parse all numbers given as strings, to real numbers (integers, floats)
 * - convert all prices to cents
 * - convert percent values to floats
 * - convert numbers as strings to real integers (mileage, termOfContract)
 *
 * expected input format: https://services.mobile.de/docs/seller-api.html#Price_Schema
 *
 * @param {*} data
 * @returns noramlized data
 */
export const getNormalizedPriceData = data => {
  const normalizedData = {
    consumerPriceGross: convertToCents(data.consumerPriceGross),
    type: 'FIXED', // TODO: For AgricultureVehicle, Bus, ConstructionMachine, ForkliftTruck, SemiTrailer, SemiTrailerTruck, Trailer, TruckOver7500, VanUpTo7500 it is possible to set price type ON_REQUEST.
    currency: 'EUR'
  }

  if (data.vatRate !== undefined) {
    normalizedData.vatRate = convertToFloat(data.vatRate)
  }

  if (data.consumerPriceNet !== undefined) {
    normalizedData.consumerPriceNet = convertToCents(data.consumerPriceNet)
  }

  if (data.amPreviousConsumerPriceGross !== undefined) {
    normalizedData.amPreviousConsumerPriceGross = convertToCents(
      data.amPreviousConsumerPriceGross
    )
  }

  if (data.amPreviousConsumerPriceNet !== undefined) {
    normalizedData.amPreviousConsumerPriceNet = convertToCents(
      data.amPreviousConsumerPriceNet
    )
  }

  if (data.amListPriceGross !== undefined) {
    normalizedData.amListPriceGross = convertToCents(data.amListPriceGross)
  }

  if (data.amListPriceNet !== undefined) {
    normalizedData.amListPriceNet = convertToCents(data.amListPriceNet)
  }

  return Object.assign({}, data, normalizedData)
}

// returns the discount in percent depending on vehicle type and visibility rules
export const getDiscountInPercent = (
  normalizedPriceData,
  vehicleType,
  maxDaysSinceLastPriceChange,
  minPriceDifferenceInPercent
) => {
  let discount = null

  if (
    isAllowedToDisplayPriceDiscounts(
      normalizedPriceData,
      vehicleType,
      maxDaysSinceLastPriceChange,
      minPriceDifferenceInPercent
    )
  ) {
    if (vehicleType.toLowerCase() === 'g') {
      discount = getDiscountToConsumerPriceInPercent(normalizedPriceData)
    } else {
      discount = getDiscountToListPriceInPercent(normalizedPriceData)
    }
  }

  return discount
}

// returns the crossed out price depending on vehicle type and visibility rules
export const getCrossedOutPrice = (
  normalizedPriceData,
  vehicleType,
  maxDaysSinceLastPriceChange,
  minPriceDifferenceInPercent
) => {
  let price = null

  if (
    isAllowedToDisplayPriceDiscounts(
      normalizedPriceData,
      vehicleType,
      maxDaysSinceLastPriceChange,
      minPriceDifferenceInPercent
    )
  ) {
    if (vehicleType.toLowerCase() === 'g') {
      if (normalizedPriceData.amPreviousConsumerPriceGross !== undefined) {
        price = normalizedPriceData.amPreviousConsumerPriceGross
      }
    } else {
      if (normalizedPriceData.amListPriceGross !== undefined) {
        price = normalizedPriceData.amListPriceGross
      }
    }
  }

  return price
}

export default {
  getNormalizedPriceData,
  getCrossedOutPrice,
  getDiscountInPercent
}
