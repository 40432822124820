import initialState from './initial-state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function createSearchHybridModule() {
  return {
    namespaced: true,
    state: initialState(),
    getters,
    actions,
    mutations
  }
}

export default {
  createSearchHybridModule
}
