export const firstUtmTouchpoint = state => {
  return state.utm.first_touchpoint
}

export const lastUtmTouchpoint = state => {
  return state.utm.last_touchpoint
}

export const alreadyHasFirstUtmTouchpoint = state => {
  return Object.values(state.utm.first_touchpoint).some(v => v !== null)
}

export default {
  firstUtmTouchpoint,
  lastUtmTouchpoint,
  alreadyHasFirstUtmTouchpoint
}
