import {
  SET_APP_MODE,
  SET_ORIGIN,
  SET_THEME,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  ADD_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_CONFIGURED_VEHICLE_CLASS,
  SET_AVAILABLE_VEHICLE_CLASS,
  ADD_INTEGRATION,
  SET_RAW_COMPANY_DEALER_DATA,
  SET_RAW_VEHICLE_MARKET_COMPANY_DATA,
  SET_MPC_API,
  SET_HAS_FETCHED_APP_DATA,
  SET_CANONICAL_BASE_URL,
  SET_GOOGLE_API_LOADING_STATE,
  SET_GOOGLE_MAPS_LOADING_STATE
} from './mutation-types'

export default {
  [SET_ORIGIN]: (state, origin) => {
    state.origin = origin
  },
  [SET_APP_MODE]: (state, mode) => {
    state.appMode = mode
  },
  [SET_THEME]: (state, theme) => {
    state.theme = theme
  },
  [SET_COMPANY_ID]: (state, id) => {
    state.company.id = id
  },
  [SET_COMPANY_NAME]: (state, name) => {
    state.company.name = name
  },
  [SET_RAW_VEHICLE_MARKET_COMPANY_DATA]: (state, data) => {
    state.rawVehicleMarketCompanyData = Object.assign(
      {},
      state.rawVehicleMarketCompanyData,
      data
    )
  },
  [SET_RAW_COMPANY_DEALER_DATA]: (state, data) => {
    state.rawCompanyDealerData = data
  },
  [ADD_NOTIFICATION]: (state, notification) => {
    state.notifications.push(
      Object.assign({}, notification, {
        id: state.notificationIdIncrement,
        timer: notification.timer || 5000
      })
    )
    state.notificationIdIncrement++
  },
  [SET_NOTIFICATIONS]: (state, notifications) => {
    state.notifications = notifications
  },
  [SET_CONFIGURED_VEHICLE_CLASS]: (state, vehicleClass) => {
    state.settings.vehicleClass.configured = vehicleClass
  },
  [SET_AVAILABLE_VEHICLE_CLASS]: (state, vehicleClass) => {
    state.settings.vehicleClass.available = vehicleClass
  },
  [ADD_INTEGRATION]: (state, integration) => {
    state.integrations.push(integration)
  },
  [SET_MPC_API]: (state, config) => {
    state.mpcApi = config
  },
  [SET_HAS_FETCHED_APP_DATA]: (state, hasFetched) => {
    state.hasFetchedAppData = hasFetched
  },
  [SET_CANONICAL_BASE_URL]: (state, url) => {
    state.canonicalBaseUrl = url
  },
  [SET_GOOGLE_API_LOADING_STATE]: (state, loaded) => {
    state.googleApi.loaded = loaded
  },
  [SET_GOOGLE_MAPS_LOADING_STATE]: (state, loaded) => {
    state.googleMaps.loaded = loaded
  }
}
