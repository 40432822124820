import initialState from './initial-state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function createFavoritesModule() {
  return {
    namespaced: true,
    state: initialState(),
    actions,
    mutations,
    getters
  }
}

export default {
  createFavoritesModule
}
