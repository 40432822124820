import initialState from '@/app/store/modules/search-hybrid/initial-state'

export const getInitialSearchParams = (state, getters, rootState) => {
  const availableVehicleClasses = rootState.core.settings.vehicleClass.available

  // default 'Car' should never happen, that would mean the data is empty
  const vehicleClass =
    availableVehicleClasses.length > 0 ? availableVehicleClasses[0] : 'Car'

  return () => {
    return Object.assign({}, initialState().params, {
      vehicleClass: [vehicleClass]
    })
  }
}

// returns the initial store params, without the vehicle class attribute
export const getInitialSearchParamsWithCurrentVehicleClass = state => {
  return () => {
    return Object.assign({}, initialState().params, {
      vehicleClass: state.params.vehicleClass
    })
  }
}

export const hasMoreVehiclesToLoad = state => {
  let hasMore = false

  if (state.paginationData !== null) {
    hasMore = state.paginationData.page < state.paginationData.pages
  }

  return hasMore
}

export default {
  getInitialSearchParams,
  getInitialSearchParamsWithCurrentVehicleClass,
  hasMoreVehiclesToLoad
}
