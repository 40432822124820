import {
  SET_APP_MODE,
  SET_ORIGIN,
  SET_THEME,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  SET_RAW_COMPANY_DEALER_DATA,
  ADD_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_CONFIGURED_VEHICLE_CLASS,
  SET_AVAILABLE_VEHICLE_CLASS,
  ADD_INTEGRATION,
  SET_RAW_VEHICLE_MARKET_COMPANY_DATA,
  SET_MPC_API,
  SET_HAS_FETCHED_APP_DATA,
  SET_CANONICAL_BASE_URL,
  SET_GOOGLE_API_LOADING_STATE,
  SET_GOOGLE_MAPS_LOADING_STATE
} from './mutation-types'
import { getCompany } from '@/app/requests/company'
import {
  getCompanyDealers,
  getVehicleMarketDealersByCompany
} from '@/app/requests/dealers'
import { validVehicleClasses } from '@/shared/definitions/vehicle-class'
import { facetVehiclesByCompany } from '@/app/requests/vehicles'
import { getVehicleMarketCompany } from '@/app/requests/company'

export const setOrigin = ({ commit }, origin) => {
  commit(SET_ORIGIN, origin)
}

export const setAppMode = ({ commit }, mode) => {
  commit(SET_APP_MODE, mode)
}

export const setTheme = ({ commit }, theme) => {
  commit(SET_THEME, theme)
}

export const setCompanyId = ({ commit }, companyId) => {
  commit(SET_COMPANY_ID, companyId)
}

export const fetchCompanyById = ({ commit }, companyId) => {
  return getCompany(companyId).then(res => {
    commit(SET_COMPANY_ID, res.company._id)
    commit(SET_COMPANY_NAME, res.company.name)
  })
}

export const fetchRawVehicleMarketCompanyData = ({ commit, rootGetters }) => {
  return getVehicleMarketCompany(rootGetters['core/companyId']).then(res => {
    commit(SET_RAW_VEHICLE_MARKET_COMPANY_DATA, res)
    return res
  })
}

// TODO
export const fetchRawVehicleMarketDealersData = ({
  state,
  commit,
  rootGetters
}) => {
  return getVehicleMarketDealersByCompany(rootGetters['core/companyId']).then(
    res => {
      const newRawCompanyDealerData = state.rawCompanyDealerData.map(d => {
        const foundExtendedData = res.find(r => r._id === d._id)

        let data = d

        if (foundExtendedData && foundExtendedData.extended) {
          data = Object.assign({}, data, foundExtendedData.extended)
        }

        if (foundExtendedData && foundExtendedData.vehicleMarket) {
          data = Object.assign({}, data, {
            vehicleMarket: foundExtendedData.vehicleMarket
          })
        }

        return data
      })

      commit(SET_RAW_COMPANY_DEALER_DATA, newRawCompanyDealerData)
    }
  )
}

export const fetchRawCompanyDealerData = ({ commit, rootGetters }) => {
  return getCompanyDealers(rootGetters['core/companyId']).then(res => {
    commit(SET_RAW_COMPANY_DEALER_DATA, res)
  })
}

export const addNotification = ({ commit, state }, notification) => {
  const newNotification = Object.assign({}, notification, {
    id: state.notificationIdIncrement,
    timer: notification.timer || 5000
  })

  commit(ADD_NOTIFICATION, newNotification)

  setTimeout(() => {
    commit(
      SET_NOTIFICATIONS,
      state.notifications.filter(n => n.id !== newNotification.id)
    )
  }, newNotification.timer)
}

// sets the configured vehicle classes (e.g. passed to the widget)
export const setConfiguredVehicleClasses = ({ commit }, vehicleClasses) => {
  if (Array.isArray(vehicleClasses)) {
    const validatedVehicleClasses = vehicleClasses.filter(v => {
      return validVehicleClasses.includes(v)
    })
    commit(SET_CONFIGURED_VEHICLE_CLASS, validatedVehicleClasses)
  }
}

// sets the vehicle classes that are available in the customer data
const setAvailableVehicleClasses = ({ commit }, vehicleClasses) => {
  if (Array.isArray(vehicleClasses)) {
    const validatedVehicleClasses = vehicleClasses.filter(v => {
      return validVehicleClasses.includes(v)
    })
    commit(SET_AVAILABLE_VEHICLE_CLASS, validatedVehicleClasses)
  }
}

// TODO: when the api is available which returns us possible values for fields, then we can replace that with the current workaround
export const fetchAvailableVehicleClasses = async ({
  state,
  commit,
  rootGetters
}) => {
  const vehicleClassesToCheck =
    state.settings.vehicleClass.configured.length > 0
      ? state.settings.vehicleClass.configured
      : validVehicleClasses

  const promises = vehicleClassesToCheck.map(vehicleClass => {
    return facetVehiclesByCompany(rootGetters['core/companyId'], {
      flags: [],
      vehicleClass
    }).then(v => {
      return {
        vehicleClass,
        count:
          Array.isArray(v.total) && v.total.length > 0 ? v.total[0].count : 0
      }
    })
  })

  return Promise.all(promises)
    .then(results => {
      setAvailableVehicleClasses(
        { commit },
        results.filter(v => v.count > 0).map(v => v.vehicleClass)
      )
    })
    .catch(err => {
      // TODD: error handling
      console.log('error handling missing', err)
    })
}

export const addIntegration = ({ state, commit }, integration) => {
  if (state.integrations.find(i => i.name === integration.name) === undefined) {
    commit(ADD_INTEGRATION, integration)
  }
}

export const setMpcApi = ({ commit }, config) => {
  commit(SET_MPC_API, config)
}

export const setHasFetchedAppData = ({ commit }, hasFetched) => {
  commit(SET_HAS_FETCHED_APP_DATA, hasFetched)
}

export const setCanonicalBaseUrl = ({ commit }, url) => {
  commit(SET_CANONICAL_BASE_URL, url)
}

export const loadGoogleApi = ({ state, commit }) => {
  if (state.googleApi.loaded === false) {
    window.initGoogleApi = () => {
      commit(SET_GOOGLE_API_LOADING_STATE, true)
    }

    const script = document.createElement('script')

    script.src =
      'https://maps.googleapis.com/maps/api/js?key=' +
      state.googleApi.key +
      '&libraries=places,marker&loading=async&callback=initGoogleApi'
    script.async = true

    document.head.appendChild(script)
  }
}

export const loadGoogleMaps = ({ state, commit }) => {
  if (state.googleApi.loaded) {
    commit(SET_GOOGLE_MAPS_LOADING_STATE, true)
    return
  }

  if (state.googleMaps.loaded === false) {
    window.initMap = () => {
      commit(SET_GOOGLE_MAPS_LOADING_STATE, true)
    }

    const script = document.createElement('script')

    script.src =
      'https://maps.googleapis.com/maps/api/js?key=' +
      state.googleApi.key +
      '&libraries=places,marker&loading=async&callback=initMap'
    script.async = true

    document.head.appendChild(script)
  }
}

export const setGoogleMapsLoadingState = ({ commit }, loaded) => {
  commit(SET_GOOGLE_MAPS_LOADING_STATE, loaded)
}

export const loadInitialData = ({ state, dispatch }) => {
  return dispatch('fetchCompanyById', state.company.id)
    .then(() => {
      return Promise.all([
        dispatch('fetchRawVehicleMarketCompanyData'),
        dispatch('fetchRawCompanyDealerData').then(() =>
          dispatch('fetchRawVehicleMarketDealersData')
        ),
        dispatch('fetchAvailableVehicleClasses')
      ]).then(() => {
        return dispatch('setHasFetchedAppData', true)
      })
    })
    .catch(err => {
      // TODO error handling
      console.log('error handling missing', err)
    })
}

export default {
  setOrigin,
  setAppMode,
  setTheme,
  setCompanyId,
  fetchCompanyById,
  fetchRawCompanyDealerData,
  fetchAvailableVehicleClasses,
  addNotification,
  setConfiguredVehicleClasses,
  addIntegration,
  fetchRawVehicleMarketCompanyData,
  fetchRawVehicleMarketDealersData,
  setMpcApi,
  setHasFetchedAppData,
  setCanonicalBaseUrl,
  setGoogleMapsLoadingState,
  loadGoogleApi,
  loadGoogleMaps,
  loadInitialData
}
