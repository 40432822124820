import { client as mpcClient } from './../../shared/http/mpc-client'

export const getCompany = companyId => {
  return mpcClient
    .post('/graphql', {
      query: `
          query GetCompany($input: ID!) {
              getCompany(_id: $input) {
                  _id
                  name
              }
          }
        `,
      variables: {
        input: companyId
      }
    })
    .then(res => {
      const data = res.data.data.getCompany
      return {
        company: {
          _id: data._id,
          name: data.name
        }
      }
    })
}

export const getVehicleMarketCompany = companyId => {
  return mpcClient
    .post('/graphql', {
      query: `
        query GetVehicleMarketCompany($input: ID!) {
            getVehicleMarketCompany(_id: $input) {
              _id
              vehicleMarket
            }
        }
      `,
      variables: {
        input: companyId
      }
    })
    .then(res => {
      return res.data.data.getVehicleMarketCompany.vehicleMarket
    })
}

export default {
  getCompany,
  getVehicleMarketCompany
}
