import { convertToFloat, convertToCents } from './../utils/converter'

/**
 * https://services.mobile.de/refdata/paybackperiods
 * @param {*} value
 * @returns mapped integer value
 */
const getPaybackPeriod = value => {
  const map = {
    MONTHS_12: 12,
    MONTHS_24: 24,
    MONTHS_36: 36,
    MONTHS_48: 48,
    MONTHS_60: 60,
    MONTHS_72: 72,
    MONTHS_84: 84,
    MONTHS_96: 96
  }

  return map[value]
}

/**
 * this method will process the incoming mobile data format in a way that it is better useable in our application.
 * it parse all numbers given as strings, to real numbers (integers, floats)
 * - convert all prices to cents
 * - convert percent values to floats
 * - convert numbers as strings to real integers (mileage, termOfContract)
 *
 * expected input format: https://services.mobile.de/docs/seller-api.html#Financing_Schema
 *
 * @param {*} financingData
 * @param {*} fallbackVatRate => will be used for mobile data because they will not deliver us vatRate information
 * @returns noramlized data
 */
export const getNormalizedFinancingData = (financingData, fallbackVatRate) => {
  // mobile actually only delivers an object with one offer, cpo is delivering an array with possibly mutiple offers

  if (
    financingData.rates === undefined &&
    financingData.vatRate === undefined &&
    Object.keys(financingData).length > 2
  ) {
    financingData = {
      vatRate: fallbackVatRate,
      rates: [financingData]
    }
  }

  return Object.assign({}, financingData, {
    vatRate:
      financingData.vatRate !== undefined
        ? convertToFloat(financingData.vatRate)
        : undefined,
    rates: financingData.rates.map(data => {
      const normalizedData = {
        annualPercentageRate: convertToFloat(data.annualPercentageRate),
        nominalInterestRate: convertToFloat(data.nominalInterestRate),
        monthlyInstallment: convertToCents(data.monthlyInstallment),
        finalInstallment: convertToCents(data.finalInstallment),
        paybackPeriod: getPaybackPeriod(data.paybackPeriod),
        netLoanAmount: convertToCents(data.netLoanAmount),
        grossLoanAmount: convertToCents(data.grossLoanAmount),
        firstInstallment: convertToCents(data.firstInstallment || 0) // will be set to 0 because rates can be calculated with the price calculator base on custom firstInstallment
      }

      if (data.closingCosts !== undefined) {
        normalizedData.closingCosts = convertToCents(data.closingCosts)
      }

      if (data.paymentProtectionInsurance !== undefined) {
        normalizedData.paymentProtectionInsurance = convertToCents(
          data.paymentProtectionInsurance
        )
      }

      return Object.assign({}, data, normalizedData)
    })
  })
}

/**
 * this method will determine and return the cheapest offer in a offer matrix
 * note: the financing data should be normalized before passing to this method. never pass in the raw mobile ad financing array.
 *
 * @param {*} financingMatrix
 * @returns finance offer object
 */
export const getCheapestFinancingOffer = financingMatrix => {
  let offers = [...financingMatrix]

  const sortedByMonthlyRate = offers.sort((a, b) => {
    return a.monthlyInstallment > b.monthlyInstallment ? 1 : -1
  })

  return sortedByMonthlyRate.length > 0 ? sortedByMonthlyRate[0] : null
}

export const getRate = offer => {
  let rate = null

  if (offer !== null) {
    const rateData = Object.assign(
      {},
      offer.data.rate.origin,
      offer.data.rate.modified
    )

    // `monthlyInstallment` is always the gross value, so we need to calculate the net value by our own
    rate = {
      type: offer.type,
      grossRate: rateData.monthlyInstallment
    }

    if (offer.data.vatRate !== undefined) {
      rate.netRate =
        rateData.monthlyInstallment / (1 + offer.data.vatRate / 100)
    }
  }

  return rate
}

export default {
  getNormalizedFinancingData,
  getCheapestFinancingOffer,
  getRate
}
