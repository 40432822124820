import { getFinancingOffer, getFinancingOfferRate } from './offer'

import { getNormalizedFinancingData } from './financing'
import { getNormalizedLeasingData } from './leasing'
import {
  getNormalizedPriceData,
  getCrossedOutPrice,
  getDiscountInPercent
} from './price'
import validUsedCarSeals from '@/shared/definitions/used-car-seals'
import { differenceInHours, parseISO, set } from 'date-fns'
import { createI18n } from '@/app/i18n/index.js'

const i18n = createI18n()

export const getId = rawVehicleData => {
  return rawVehicleData.mobileAdId
}

export const getOfferId = rawVehicleData => {
  return rawVehicleData.internalNumber
}

export const getTitle = rawVehicleData => {
  return (
    i18n.t('vehicle.make' + rawVehicleData.make) + ' ' + rawVehicleData.model
  )
}

// N, D, V, G are shortcuts of CPO, not mobile, will be used for our crossed out prices or legal text for example
// never change the return value
// if you just want to display the vehicle type in frontend, use the vehicle-feature-helper mixin instead
export const getTypeLetter = rawVehicleData => {
  let type

  if (rawVehicleData.condition === 'NEW') {
    type = 'N'
  } else {
    if (rawVehicleData.usageType === 'EMPLOYEES_CAR') {
      type = 'D'
    } else if (rawVehicleData.usageType === 'DEMONSTRATION') {
      type = 'V'
    } else {
      type = 'G'
    }
  }

  return type
}

// TODOS: this should be normalized in backend api!
// but also here, we may need some possibilities to render breakpoint specific images for performance reasons
export const getGalleryImages = rawVehicleData => {
  let images = []

  if (rawVehicleData.media && Array.isArray(rawVehicleData.media.gallery)) {
    // cpo image sources
    images = rawVehicleData.media.gallery.map(image => ({
      normal: image.original2,
      zoom: image.original2,
      type: 'cpo',
      alt: getTitle(rawVehicleData)
    }))
  } else if (rawVehicleData.images && Array.isArray(rawVehicleData.images)) {
    // mobile image sources
    images = rawVehicleData.images.map(image => ({
      normal: '//' + image.baseUrl + '_20.JPG', // TODO: it is not large enough, ots 800 but we need at least 870
      zoom: '//' + image.baseUrl + '_57.JPG',
      type: 'mobile',
      alt: getTitle(rawVehicleData)
    }))
  }

  return images
}

export const getDefaultImage = (defaultImages, rawVehicleData) => {
  let source = ''

  const foundDefaultImage = defaultImages.find(defaultImage => {
    return Object.keys(defaultImage.condition).every(
      property =>
        rawVehicleData[property] !== undefined &&
        rawVehicleData[property] === defaultImage.condition[property]
    )
  })

  if (foundDefaultImage !== undefined) {
    source = foundDefaultImage.source
  }

  return source
}

export const getPreviewImage = rawVehicleData => {
  const images = getGalleryImages(rawVehicleData)
  return images.length > 0 ? images[0] : null
}

// 16.08.2022: should be obsolet to reduce complexity, have used own methods in new vdp 2.0
// TBD
// get default values for settings from an global config?
// TODO: check if settings are all time bound with store settings?, check all getPricing calls
export const getPricing = (rawVehicleData, settings) => {
  settings = Object.assign(
    {
      isCommercial: false,
      maxDaysSinceLastPriceChange: 3,
      minPriceDifferenceInPercent: 10
    },
    settings
  )

  const vehicleTypeLetter = getTypeLetter(rawVehicleData)

  const normalizedPriceData =
    rawVehicleData?.price !== undefined
      ? getNormalizedPriceData(rawVehicleData.price)
      : null

  const normalizedFinancingData =
    rawVehicleData?.financing !== undefined
      ? getNormalizedFinancingData(
          rawVehicleData.financing,
          rawVehicleData.price.vatRate
        )
      : null

  const normalizedLeasingData =
    rawVehicleData?.leasing !== undefined
      ? getNormalizedLeasingData(rawVehicleData.leasing)
      : null

  const financingOffer = getFinancingOffer(
    normalizedLeasingData,
    normalizedFinancingData,
    settings.isCommercial
  )

  if (
    !normalizedPriceData &&
    !normalizedFinancingData &&
    !normalizedLeasingData
  )
    return

  const rate = getFinancingOfferRate(financingOffer)

  const crossedOutPrice = getCrossedOutPrice(
    normalizedPriceData,
    vehicleTypeLetter,
    settings.maxDaysSinceLastPriceChange,
    settings.minPriceDifferenceInPercent
  )

  const discountInPercent = getDiscountInPercent(
    normalizedPriceData,
    vehicleTypeLetter,
    settings.maxDaysSinceLastPriceChange,
    settings.minPriceDifferenceInPercent
  )

  // the financingOffer can be either a leasing offer, or a private financing offer
  return {
    financingOffer: {
      data: financingOffer,
      computed: {
        rate
      },
      methods: {
        getRateByOffer: offer => getFinancingOfferRate(offer)
      }
    },
    offer: {
      data: normalizedPriceData,
      computed: {
        crossedOutPrice,
        discountInPercent
      },
      methods: {}
    },
    normalized: {
      price: normalizedPriceData,
      leasing: normalizedLeasingData,
      financing: normalizedFinancingData
    }
  }
}

export const hasUsedCarSeal = rawVehicleData => {
  let hasSeal = false

  if (rawVehicleData !== null) {
    hasSeal =
      typeof rawVehicleData.usedCarSeal === 'string' &&
      validUsedCarSeals.includes(rawVehicleData.usedCarSeal.toLowerCase())
  }

  return hasSeal
}

export const usedCarSealSources = rawVehicleData => {
  const sources = []

  if (hasUsedCarSeal(rawVehicleData)) {
    sources.push({
      srcset:
        'https://cdn.automedia.de/widgets/vehicle-market/assets/used-car-seals/' +
        rawVehicleData.usedCarSeal.toLowerCase() +
        '.svg',
      media: null
    })
  }

  return sources
}

export const getDeliveryDateInWeeks = date => {
  let weeks = 0

  const deliveryDate = parseISO(date)
  const todayDate = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  weeks = differenceInHours(deliveryDate, todayDate) / (7 * 24)

  return weeks < 0 ? 0 : Math.ceil(weeks)
}

export default {
  getTitle,
  getTypeLetter,
  getGalleryImages,
  getPreviewImage,
  getDefaultImage,
  getPricing,
  hasUsedCarSeal,
  usedCarSealSources,
  getDeliveryDateInWeeks
}
