/* eslint-disable */
import { createApp } from "../app";
import defaultConfig from './../default-config'
import { setOptions as setMpcClientOptions } from './../../shared/http/mpc-client'
import createPersistedState from './../store/plugins/persisted-state'
import { createStore } from './../store'

// client-specific bootstrapping logic...
const appConfig = Object.assign({}, defaultConfig, {})

const store = createStore({ ssr: false })

if (window.__INITIAL_STATE__) {
    // We initialize the store state with the data injected from the server
    store.replaceState(window.__INITIAL_STATE__);
}

const { app, router } = createApp(appConfig, { store });

createPersistedState(store)

const mpcApi = store.getters['core/mpcApi']

setMpcClientOptions({
  token: mpcApi.token,
  companyId: mpcApi.companyId,
  baseUrl: mpcApi.baseUrl
})

router.onReady(() => {
  // this assumes App.vue template root element has `id="app"`
  app.$mount("#app");
})

