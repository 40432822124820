<template>
  <div
    class="am-notification"
    :class="{
      ['am-notification--' + type]: validTypes.includes(type)
    }"
  >
    <amui-icon :name="iconName" size="md" class="am-notification__icon" />
    <div class="am-notification__message">
      {{ message }}
    </div>
  </div>
</template>

<script>
// did this without utility classes to keep possibility to move that component over to AMUI

const VALID_TYPES = ['success', 'error']
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  name: 'AmNotification',

  components: {
    AmuiIcon
  },

  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'success',
      validator(value) {
        return VALID_TYPES.includes(value)
      }
    }
  },

  computed: {
    validTypes() {
      return VALID_TYPES
    },
    iconName() {
      const mapping = {
        success: 'check',
        error: 'warning'
      }

      return mapping[this.type]
    }
  }
}
</script>
