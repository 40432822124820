export default () => ({
  utm: {
    first_touchpoint: {
      source: null,
      medium: null,
      campaign: null,
      term: null,
      content: null
    },
    last_touchpoint: {
      source: null,
      medium: null,
      campaign: null,
      term: null,
      content: null
    }
  }
})
