<template>
  <component :is="htmlTag" class="amui-headline" :class="classes">
    <template v-if="text">
      {{ text }}
    </template>
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AmuiHeadline',

  props: {
    text: {
      required: false,
      type: String
    },
    htmlTag: {
      type: String,
      required: false,
      default: 'h2',
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value)
      }
    },
    looksLike: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
      }
    },
    underlined: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    computedLooksLike() {
      let looksLike = this.looksLike

      if (looksLike === null) {
        if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(this.htmlTag)) {
          looksLike = this.htmlTag
        } else {
          looksLike = 'h2'
        }
      }

      return looksLike
    },
    classes() {
      return ['amui-headline--' + this.computedLooksLike]
    }
  }
}
</script>
