export const services = state => {
  return state.services
}

export const allowedServices = state => {
  return state.allowedServices
}

export default {
  services,
  allowedServices
}
