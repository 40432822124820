<template>
  <div class="am-notifications">
    <transition name="am-notifications-fx">
      <am-notification
        v-if="notification"
        :key="notification.id"
        :message="notification.message"
        :type="notification.type"
        class="am-notifications__notification"
      />
    </transition>
  </div>
</template>

<script>
import AmNotification from './notification.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AmNotifications',

  components: {
    AmNotification
  },

  computed: {
    ...mapGetters('core', ['notification'])
  }
}
</script>
