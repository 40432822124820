import Vue from 'vue'
import { ALLOW_SERVICE, DISALLOW_SERVICE } from './mutation-types'

export default {
  [ALLOW_SERVICE]: (state, service) => {
    const services = Array.isArray(service) ? service : [service]

    const allowedServices = [...state.allowedServices]

    services.forEach(service => {
      if (!allowedServices.includes(service)) {
        allowedServices.push(service)
      }
    })

    Vue.set(state, 'allowedServices', allowedServices)
  },
  [DISALLOW_SERVICE]: (state, service) => {
    const services = Array.isArray(service) ? service : [service]

    state.allowedServices = state.allowedServices.filter(
      allowedService => services.find(s => s === allowedService) === undefined
    )
  }
}
