<template>
  <div class="am-userlike-privacy-dialog">
    <button class="am-userlike-privacy-dialog__close" @click="close">
      <amui-icon name="close" />
    </button>
    <div class="am-userlike-privacy-dialog__content">
      <amui-headline
        :text="$t('userlike.privacyDialog.headline')"
        html-tag="h2"
        looks-like="h5"
      />
      <p>
        {{ $t('userlike.privacyDialog.text') }}
      </p>
      <div class="am-userlike-privacy-dialog__actions">
        <amui-button
          type="primary"
          htmlTag="button"
          :label="$t('userlike.privacyDialog.cta.allow.label')"
          @click.native="$emit('confirm')"
        />
        <amui-button
          :flat="true"
          htmlTag="button"
          :label="$t('userlike.privacyDialog.cta.settings.label')"
          @click.native="$emit('cookie-settings')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'

export default {
  name: 'UserlikePrivacyDialog',

  components: {
    AmuiIcon,
    AmuiHeadline,
    AmuiButton
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
