/**
 * this method will simply parse the given value to float
 *
 * @param {*} value
 * @returns float value
 */
export const convertToFloat = value => {
  return parseFloat(value)
}

/**
 * this method converts the given price values as strings to cent values as integers.
 * the input needs to be a price value from the mobile api definition, like:
 * "100" (which means 100 Euro without cents) or "100.45" (which means 100,45 Euro with cents)
 *
 * Attention: All other input values might result in unexpected behavior and price calculations!
 *
 * @param {*} value
 * @returns float value
 */
export const convertToCents = value => {
  // reponse can be like  41743.70, 29947.5, 38980.00 or 28860.0
  return parseInt(
    parseFloat(value)
      .toFixed(2)
      .replace(/[^0-9]/g, '')
  )
}

export default {
  convertToFloat,
  convertToCents
}
