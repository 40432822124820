<template>
  <component
    :is="computedHtmlTag"
    class="amui-button"
    :class="classes"
    @click="onClick"
    :href="computedHref"
  >
    <amui-icon v-if="icon" :name="icon" class="amui-button__icon" />
    <span v-if="label">{{ label }}</span>
    <amui-icon v-if="iconRight" :name="iconRight" class="amui-button__icon" />
  </component>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  name: 'AmuiButton',

  components: {
    AmuiIcon
  },

  props: {
    type: {
      type: String,
      required: false,
      default: 'primary',
      validator: type => ['primary', 'secondary', 'attention'].includes(type)
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    iconRight: {
      type: String,
      required: false,
      default: null
    },
    outline: {
      type: Boolean,
      required: false,
      default: false
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    htmlTag: {
      type: String,
      required: false,
      default: 'button',
      validator: tag => ['a', 'button', 'div'].includes(tag)
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator(value) {
        return ['sm', 'md'].includes(value)
      }
    },
    to: {
      type: [String, Object],
      required: false,
      default: null
    },
    href: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    classes() {
      const classes = []

      classes.push({
        'amui-button--outline': this.outline && !this.flat,
        'amui-button--flat': this.flat && !this.outline,
        'amui-button--icon-only': this.icon !== null && this.label === null,
        ['amui-button--' + this.type]: this.type,
        'amui-button--hoverable': !this.disabled,
        'amui-button--disabled': this.disabled,
        'amui-button--size-sm': this.size && this.size === 'sm'
      })

      return classes
    },
    computedHref() {
      let href = null
      if (this.to) {
        const resolved = this.$router.resolve(this.to)
        href = resolved.href
      } else {
        if (this.href) {
          href = this.href
        }
      }
      return href
    },
    computedHtmlTag() {
      return this.computedHref !== null ? 'a' : this.htmlTag
    }
  },
  methods: {
    onClick(e) {
      if (this.disabled === true) {
        e.preventDefault()
      } else {
        this.$emit('click', e)
      }
    }
  }
}
</script>
