import initialState from './initial-state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export function createCoreModule() {
  return {
    namespaced: true,
    state: initialState(),
    actions,
    mutations,
    getters
  }
}

export default {
  createCoreModule
}
