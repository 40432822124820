<template>
  <div class="amui-icon" aria-hidden="true" role="presentation">
    <div class="amui-icon__inner">
      <component
        :is="iconComponentName"
        class="amui-icon__svg"
        height="100%"
        width="100%"
        preserve-aspect-ratio="xMidYMid meet"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmuiIcon',

  props: {
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    iconComponentName() {
      return 'icon-' + this.name
    }
  }
}
</script>
