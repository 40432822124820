import IconCall from './call.svg'
import IconChat from './chat.svg'
import IconFavorite from './favorite.svg'
import IconFavoriteFilled from './favorite-filled.svg'
import IconPrint from './print.svg'
import IconShare from './share.svg'
import IconCalendarToday from './calendar-today.svg'
import IconDirectionsCar from './directions-car.svg'
import IconFuelStation from './fuel-station.svg'
import IconGear from './gear.svg'
import IconList from './list.svg'
import IconLocationOn from './location-on.svg'
import IconStreet from './street.svg'
import IconClose from './close.svg'
import IconCrosshairs from './crosshairs.svg'
import IconCloseCircle from './close-circle.svg'
import IconEngine from './engine.svg'
import IconSearch from './search.svg'
import IconArrowUpward from './arrow-upward.svg'
import IconArrowDownward from './arrow-downward.svg'
import IconDirections from './directions.svg'
import IconSliderThumb from './slider-thumb.svg'
import IconKeyboardArrowUp from './keyboard-arrow-up.svg'
import IconKeyboardArrowDown from './keyboard-arrow-down.svg'
import IconArrowLeftAlt from './arrow-left-alt.svg'
import IconArrowDownAlt from './arrow-downward.svg'
import IconArrowRightAlt from './arrow-right-alt.svg'
import IconFilter from './filter.svg'
import ArrowDropDown from './arrow-drop-down.svg'
import IconCheck from './check.svg'
import IconCheckCircle from './check-circle.svg'
import ArrowBack from './arrow-back.svg'
import Delete from './delete.svg'
import IconArrowBack from './arrow-back.svg'
import IconMoney from './money.svg'
import IconPlayListAddCheck from './playlist_add_check.svg'
import IconVideoCall from './video_call.svg'
import IconBusinessCompany from './business_company.svg'
import IconRestore from './restore.svg'
import IconMoreVert from './more-vert.svg'
import IconImage from './image.svg'
import IconFullScreen from './fullscreen.svg'
import Icon360interior from './360-interior.svg'
import Icon360RotationOpenDoors from './360-rotation-open-doors.svg'
import Icon360Rotation from './360-rotation.svg'
import Icon360FooterRotation from './360-rotate-icon.svg'
import Icon360FooterRotationOpenDoors from './360-rotate-open-doors-icon.svg'
import IconInfo from './info.svg'
import IconArrowSlideLeft from './arrow-slide-left.svg'
import IconArrowSlideRight from './arrow-slide-right.svg'
import IconSend from './send.svg'
import IconEmail from './email.svg'
import IconNotInterested from './not-interested.svg'
import IconWarning from './warning.svg'
import IconSortAscending from './sort-ascending.svg'
import IconSortDescending from './sort-descending.svg'
import IconChevronDown from './chevron-down.svg'
import IconAdd from './add.svg'
import IconLeather from './leather.svg'
import IconLeatherFilled from './leather-filled.svg'
import IconCloth from './cloth.svg'
import IconClothFilled from './cloth-filled.svg'
import IconDriveTypeAll from './drive-type-all.svg'
import IconDriveTypeFront from './drive-type-front.svg'
import IconDriveTypeRear from './drive-type-rear.svg'
import IconCircle from './circle.svg'
import IconCircleFilled from './circle-filled.svg'
import IconFuelPetrol from './fuel-petrol.svg'
import IconFuelHybrid from './fuel-hybrid.svg'
import IconFuelElectric from './fuel-electric.svg'
import IconMoreBox from './more-box.svg'
import IconLessBox from './less-box.svg'
import IconLabel from './label.svg'
import IconTune from './tune.svg'
import IconTuneSimple from './tune-simple.svg'

// vehicles
import Car from './vehicles/car.svg'
import Caravan from './vehicles/caravan.svg'
import MotorHome from './vehicles/motor-home.svg'
import Motorbike from './vehicles/motorbike.svg'
import Truck from './vehicles/truck.svg'

// cars
import Cabriolet from './vehicles/cars/cabriolet.svg'
import Estate from './vehicles/cars/estate.svg'
import OtherCar from './vehicles/cars/other-car.svg'
import Saloon from './vehicles/cars/saloon.svg'
import SmallCar from './vehicles/cars/small-car.svg'
import SportsCar from './vehicles/cars/sports-car.svg'
import Suv from './vehicles/cars/suv.svg'
import Van from './vehicles/cars/van.svg'

// motorbikes
import Chopper from './vehicles/motorbikes/chopper.svg'
import Combination from './vehicles/motorbikes/combination.svg'
import DirtBike from './vehicles/motorbikes/dirt-bike.svg'
import Enduro from './vehicles/motorbikes/enduro.svg'
import Lightweight from './vehicles/motorbikes/lightweight.svg'
import SmallMoped from './vehicles/motorbikes/small-moped.svg'
import Motorcycle from './vehicles/motorbikes/motorcycle.svg'
import NakedBike from './vehicles/motorbikes/naked-bike.svg'
import PocketBike from './vehicles/motorbikes/pocket-bike.svg'
import Quad from './vehicles/motorbikes/quad.svg'
import Racing from './vehicles/motorbikes/racing.svg'
import RallyCross from './vehicles/motorbikes/rally-cross.svg'
import Scooter from './vehicles/motorbikes/scooter.svg'
import SportTouring from './vehicles/motorbikes/sport-touring.svg'
import SportsBike from './vehicles/motorbikes/sports-bike.svg'
import Streetfighter from './vehicles/motorbikes/streetfighter.svg'
import SuperMoto from './vehicles/motorbikes/super-moto.svg'
import Tourer from './vehicles/motorbikes/tourer.svg'
import Trike from './vehicles/motorbikes/trike.svg'
import OtherMotorbike from './vehicles/motorbikes/other-motorbike.svg'

// social
import IconFacebook from './social-media/facebook.svg'
import IconGoogle from './social-media/google.svg'
import IconInstagram from './social-media/instagram.svg'
import IconLinkedin from './social-media/linkedin.svg'
import IconPinterest from './social-media/pinterest.svg'
import IconTwitter from './social-media/twitter.svg'
import IconYoutube from './social-media/youtube.svg'
// logo
import IconAMLogo from './logo.svg'

export default Vue => {
  Vue.component('icon-call', IconCall)
  Vue.component('icon-chat', IconChat)
  Vue.component('icon-favorite', IconFavorite)
  Vue.component('icon-favorite-filled', IconFavoriteFilled)
  Vue.component('icon-print', IconPrint)
  Vue.component('icon-share', IconShare)
  Vue.component('icon-calendar-today', IconCalendarToday)
  Vue.component('icon-directions-car', IconDirectionsCar)
  Vue.component('icon-fuel-station', IconFuelStation)
  Vue.component('icon-gear', IconGear)
  Vue.component('icon-list', IconList)
  Vue.component('icon-location-on', IconLocationOn)
  Vue.component('icon-street', IconStreet)
  Vue.component('icon-close', IconClose)
  Vue.component('icon-close-circle', IconCloseCircle)
  Vue.component('icon-crosshairs', IconCrosshairs)
  Vue.component('icon-engine', IconEngine)
  Vue.component('icon-search', IconSearch)
  Vue.component('icon-arrow-upward', IconArrowUpward)
  Vue.component('icon-arrow-downward', IconArrowDownward)
  Vue.component('icon-facebook', IconFacebook)
  Vue.component('icon-google', IconGoogle)
  Vue.component('icon-instagram', IconInstagram)
  Vue.component('icon-linkedin', IconLinkedin)
  Vue.component('icon-pinterest', IconPinterest)
  Vue.component('icon-twitter', IconTwitter)
  Vue.component('icon-youtube', IconYoutube)
  Vue.component('icon-directions', IconDirections)
  Vue.component('icon-slider-thumb', IconSliderThumb)
  Vue.component('icon-keyboard-arrow-up', IconKeyboardArrowUp)
  Vue.component('icon-keyboard-arrow-down', IconKeyboardArrowDown)
  Vue.component('icon-arrow-left-alt', IconArrowLeftAlt)
  Vue.component('icon-arrow-down-alt', IconArrowDownAlt)
  Vue.component('icon-arrow-right-alt', IconArrowRightAlt)
  Vue.component('icon-filter', IconFilter)
  Vue.component('icon-arrow-drop-down', ArrowDropDown)
  Vue.component('icon-check', IconCheck)
  Vue.component('icon-check-circle', IconCheckCircle)
  Vue.component('icon-arrow-back', ArrowBack)
  Vue.component('icon-delete', Delete)
  Vue.component('icon-cabriolet', Cabriolet)
  Vue.component('icon-car', Car)
  Vue.component('icon-caravan', Caravan)
  Vue.component('icon-motor-home', MotorHome)
  Vue.component('icon-motorbike', Motorbike)
  Vue.component('icon-truck', Truck)
  Vue.component('icon-back', IconArrowBack)
  Vue.component('icon-money', IconMoney)
  Vue.component('icon-playlist-add-check', IconPlayListAddCheck)
  Vue.component('icon-video-call', IconVideoCall)
  Vue.component('icon-business-company', IconBusinessCompany)
  Vue.component('icon-restore', IconRestore)
  Vue.component('icon-more-vert', IconMoreVert)
  Vue.component('icon-image', IconImage)
  Vue.component('icon-full-screen', IconFullScreen)
  Vue.component('icon-360-interior', Icon360interior)
  Vue.component('icon-360-rotation-open-doors', Icon360RotationOpenDoors)
  Vue.component('icon-360-rotation', Icon360Rotation)
  Vue.component('icon-360-footer-rotation', Icon360FooterRotation)
  Vue.component(
    'icon-360-footer-rotation-open-doors',
    Icon360FooterRotationOpenDoors
  )
  Vue.component('icon-info', IconInfo)
  Vue.component('icon-arrow-slide-left', IconArrowSlideLeft)
  Vue.component('icon-arrow-slide-right', IconArrowSlideRight)
  Vue.component('icon-send', IconSend)
  Vue.component('icon-email', IconEmail)
  Vue.component('icon-not-interested', IconNotInterested)
  Vue.component('icon-warning', IconWarning)
  Vue.component('icon-sort-ascending', IconSortAscending)
  Vue.component('icon-sort-descending', IconSortDescending)
  Vue.component('icon-chevron-down', IconChevronDown)
  Vue.component('icon-add', IconAdd)
  Vue.component('icon-am-logo', IconAMLogo)
  Vue.component('icon-leather', IconLeather)
  Vue.component('icon-leather-filled', IconLeatherFilled)
  Vue.component('icon-cloth', IconCloth)
  Vue.component('icon-cloth-filled', IconClothFilled)
  Vue.component('icon-drive-type-all', IconDriveTypeAll)
  Vue.component('icon-drive-type-front', IconDriveTypeFront)
  Vue.component('icon-drive-type-rear', IconDriveTypeRear)
  Vue.component('icon-circle', IconCircle)
  Vue.component('icon-circle-filled', IconCircleFilled)
  Vue.component('icon-fuel-petrol', IconFuelPetrol)
  Vue.component('icon-fuel-hybrid', IconFuelHybrid)
  Vue.component('icon-fuel-electric', IconFuelElectric)
  Vue.component('icon-more-box', IconMoreBox)
  Vue.component('icon-less-box', IconLessBox)
  Vue.component('icon-label', IconLabel)
  Vue.component('icon-tune', IconTune)
  Vue.component('icon-tune-simple', IconTuneSimple)

  // cars
  Vue.component('icon-car-cabriolet', Cabriolet)
  Vue.component('icon-car-estate', Estate)
  Vue.component('icon-car-other', OtherCar)
  Vue.component('icon-car-saloon', Saloon)
  Vue.component('icon-car-small-car', SmallCar)
  Vue.component('icon-car-sports-car', SportsCar)
  Vue.component('icon-car-suv', Suv)
  Vue.component('icon-car-van', Van)
  // motorbikes
  Vue.component('icon-motorbike-chopper', Chopper)
  Vue.component('icon-motorbike-combination', Combination)
  Vue.component('icon-motorbike-dirt-bike', DirtBike)
  Vue.component('icon-motorbike-enduro', Enduro)
  Vue.component('icon-motorbike-lightweight', Lightweight)
  Vue.component('icon-motorbike-small-moped', SmallMoped)
  Vue.component('icon-motorbike-motorcycle', Motorcycle)
  Vue.component('icon-motorbike-naked-bike', NakedBike)
  Vue.component('icon-motorbike-pocket-bike', PocketBike)
  Vue.component('icon-motorbike-quad', Quad)
  Vue.component('icon-motorbike-racing', Racing)
  Vue.component('icon-motorbike-rally-cross', RallyCross)
  Vue.component('icon-motorbike-scooter', Scooter)
  Vue.component('icon-motorbike-sport-touring', SportTouring)
  Vue.component('icon-motorbike-sports-bike', SportsBike)
  Vue.component('icon-motorbike-streetfighter', Streetfighter)
  Vue.component('icon-motorbike-super-moto', SuperMoto)
  Vue.component('icon-motorbike-tourer', Tourer)
  Vue.component('icon-motorbike-trike', Trike)
  Vue.component('icon-motorbike-other', OtherMotorbike)
}
