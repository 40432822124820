import { GoogleTagManagerTracker } from './GoogleTagManagerTracker'

const Singleton = (function() {
  let instance

  function createInstance() {
    return new GoogleTagManagerTracker()
  }

  return {
    getInstance: function() {
      if (instance === undefined) {
        instance = createInstance()
      }
      return instance
    }
  }
})()

export const getGTMTracker = () => {
  return Singleton.getInstance()
}

export default {
  getGTMTracker
}
