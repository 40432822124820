import { getVehicleById } from '@/app/requests/vehicle'
import { searchVehiclesByCompany } from '@/app/requests/vehicles'
import {
  SET_RAW_VEHICLE_DATA,
  SET_RAW_CROSS_SELLING_VEHICLE_DATA,
  SET_VEHICLE_FOUND_STATE
} from './mutation-types'

export const fetchRawVehicleData = ({ commit, rootGetters }, id) => {
  return getVehicleById(rootGetters['core/companyId'], id).then(res => {
    commit(SET_RAW_VEHICLE_DATA, res)

    if (res === null) {
      commit(SET_VEHICLE_FOUND_STATE, false)
      throw new Error('Vehicle not found')
    } else {
      commit(SET_VEHICLE_FOUND_STATE, true)
    }

    return res
  })
}

export const fetchRawCrossSellingVehicleData = (
  { commit, rootGetters, state },
  { params, options }
) => {
  return searchVehiclesByCompany(
    rootGetters['core/companyId'],
    params,
    options
  ).then(res => {
    // TODO: this filter should be removed as soon as backend provides search param to exclude certain vehicle ids
    res.docs = res.docs.filter(doc => doc._id !== state.rawVehicleData._id)

    commit(SET_RAW_CROSS_SELLING_VEHICLE_DATA, res.docs)

    return res
  })
}

export const resetRawCrossSellingVehicleData = ({ commit }) => {
  commit(SET_RAW_CROSS_SELLING_VEHICLE_DATA, null)
}

export const resetState = ({ commit }) => {
  commit(SET_RAW_VEHICLE_DATA, null)
  commit(SET_RAW_CROSS_SELLING_VEHICLE_DATA, null)
}

export default {
  fetchRawVehicleData,
  fetchRawCrossSellingVehicleData,
  resetRawCrossSellingVehicleData,
  resetState
}
