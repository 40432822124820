import { client as mpcClient } from './../../shared/http/mpc-client'

export const getVehicleById = (companyId, mobileAdId) => {
  return mpcClient
    .post('/graphql', {
      query: `
        query GetVehicleByMobileAdId($_company:ID!, $mobileAdId: ID!) {
            getVehicleByMobileAdId(_company: $_company, mobileAdId: $mobileAdId) {
                _id
                json
            }
        }
      `,
      variables: {
        _company: companyId,
        mobileAdId: mobileAdId
      }
    })
    .then(res => {
      let response = null

      if (res.status === 200 && res.data?.data?.getVehicleByMobileAdId) {
        response = JSON.parse(res.data.data.getVehicleByMobileAdId.json)
      }

      return response
    })
}

export default {
  getVehicleById
}
