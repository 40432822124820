// INFO: only use this store module for ssr/hydration reasons for fetched data by api
export default () => ({
  rawVehicleData: null,
  paginationData: {
    total: null,
    page: 1,
    pages: null,
    offset: 0,
    limit: 20
  },
  params: {
    vehicleClass: [],
    make: [],
    modelGroup: [],
    model: [],
    amModelCode: [],
    category: [],
    condition: [],
    usageType: [],
    amUsageType: [],
    fuel: [],
    gearbox: [],
    drivingMode: [],
    exteriorColor: [],
    interiorColor: [],
    trailerCouplingType: null,
    parkingAssistants: [],
    speedControl: null,
    flags: [],
    interiorType: [],
    airbag: null,
    firstRegistration: {
      min: null,
      max: null
    },
    cubicCapacity: {
      min: null,
      max: null
    },
    power: {
      min: null,
      max: null
    },
    radio: [],
    climatisation: [],
    emissionClass: [],
    emissionSticker: [],
    particulateFilterDiesel: null,
    consumptionCombined: null,
    mileage: {
      min: null,
      max: null
    },
    price: {
      min: null,
      max: null
    },
    consumerPriceGrossRange: {
      min: null,
      max: null
    },
    financingMonthlyInstallmentRange: {
      min: null,
      max: null
    },
    leasingGrossRateRange: {
      min: null,
      max: null
    },
    hasCommercialLeasingOffer: null,
    bendingLightsType: [],
    headlightType: [],
    daytimeRunningLamps: [],
    breakdownService: [],
    vatDeductible: null,
    usedCarSeal: [],
    onlineRange: null
  }
})
