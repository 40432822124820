<template>
  <div class="am-footer">
    <div class="am-footer__inner">
      <nav class="am-footer__menu">
        <a
          v-for="(link, index) in metaNavigation"
          :key="index"
          :href="link.href"
          target="_blank"
          class="am-footer__menu-item"
          rel="nofollow noopener"
        >
          {{ link.text }}
        </a>
        <a
          key="cookie"
          href="#"
          class="am-footer__menu-item"
          @click.stop.prevent="openCookies"
        >
          Cookies
        </a>
      </nav>
      <div class="am-footer__socials">
        <a
          v-for="(link, index) in socialMediaLinks"
          :key="index"
          :href="link.href"
          :title="link.text"
          rel="nofollow noopener"
          target="_blank"
        >
          <amui-icon :name="link.name" class="am-footer__social" />
        </a>
      </div>
      <hr />
      <div class="am-footer__copyrights">
        <a
          href="https://www.automedia.de/"
          rel="noopener nofollow"
          target="_blank"
          aria-label="Powered by Automedia.de"
        >
          <amui-icon name="am-logo" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon/index'
export default {
  name: 'Footer',

  components: {
    AmuiIcon
  },

  props: {
    socialMediaLinks: {
      type: Array,
      required: false,
      default: () => []
    },
    metaNavigation: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  methods: {
    openCookies() {
      window.UC_UI.showSecondLayer()
    }
  }
}
</script>
