import axios from 'axios'

export const client = axios.create({
  headers: {
    contentType: 'application/json'
  }
})

export const setBaseUrl = baseUrl => {
  client.defaults.baseURL = baseUrl
}

export const setToken = (token, companyId) => {
  client.defaults.headers.post['x-api-key'] = token
  client.defaults.headers.post['x-am-company-id'] = companyId
}

export const setOptions = options => {
  if (options.token && options.companyId) {
    setToken(options.token, options.companyId)
  }
  if (options.baseUrl) {
    setBaseUrl(options.baseUrl)
  }
}

export default {
  setBaseUrl,
  setToken,
  setOptions,
  client
}
