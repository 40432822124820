import Vue from 'vue'

import {
  SET_RAW_VEHICLE_DATA,
  SET_RAW_CROSS_SELLING_VEHICLE_DATA,
  SET_VEHICLE_FOUND_STATE
} from './mutation-types'

export default {
  [SET_RAW_VEHICLE_DATA]: (state, data) => {
    Vue.set(state, 'rawVehicleData', data)
  },
  [SET_RAW_CROSS_SELLING_VEHICLE_DATA]: (state, data) => {
    Vue.set(state, 'rawCrossSellingVehicleData', data)
  },
  [SET_VEHICLE_FOUND_STATE]: (state, hasFound) => {
    Vue.set(state, 'hasFoundVehicle', hasFound)
  }
}
