export class GoogleTagManagerTracker {
  static serviceName = 'gtm'
  static scriptUrl =
    'https://www.googletagmanager.com/gtm.js?id=<<propertyId>>&l=amDataLayer'

  #enabled = false
  #property = null
  #dataLayerCache = []

  constructor(property) {
    window.amDataLayer = window.amDataLayer || []

    this.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime()
    })

    this.#property = property
  }

  setProperty(property) {
    this.#property = property
  }

  getProperty() {
    return this.#property
  }

  getServiceName() {
    return GoogleTagManagerTracker.serviceName
  }

  push(trackObj) {
    window.amDataLayer.push(trackObj)
  }

  getScriptIdentifier() {
    return GoogleTagManagerTracker.scriptUrl
  }

  getScriptUrl() {
    return GoogleTagManagerTracker.scriptUrl.replace(
      '<<propertyId>>',
      this.#property
    )
  }

  cache(trackObject) {
    this.#dataLayerCache.push(trackObject)
  }

  flush() {
    if (this.#enabled) {
      this.#dataLayerCache.forEach(trackObject => {
        this.push(trackObject)
      })
      this.#dataLayerCache = []
    }
  }

  enable() {
    this.#enabled = true
    this.flush()
  }

  disable() {
    this.#enabled = false
  }

  trackEvent(event) {
    const trackObject = Object.assign({}, { event: event.name }, event.payload)

    const trackMethod = this.#enabled ? this.push : this.cache.bind(this)

    if (event.payload?.ecommerce !== undefined) {
      trackMethod({ ecommerce: null })
    }

    trackMethod(trackObject)
  }
}
