<template>
  <div class="amui-badge" :class="{ 'amui-badge--small': small }">
    <span class="amui-badge__label">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AmuiBadge',

  props: {
    label: {
      required: true,
      type: [String, Number]
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
