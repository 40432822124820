import initialState from './initial-state'
import actions from './actions'
import mutations from './mutations'

export function createVehicleDetailPageModule() {
  return {
    namespaced: true,
    state: initialState(),
    actions,
    mutations
  }
}

export default {
  createVehicleDetailPageModule
}
