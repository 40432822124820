<template>
  <header
    class="am-header"
    :class="{
      'am-header--open': mobileNavOpen,
      'am-header--no-transitions': preventTransitions
    }"
  >
    <div class="am-header__inner">
      <div class="am-header__content-wrapper">
        <div
          @click="onClickNavigationBackground"
          class="am-header__navigation-background"
        ></div>
        <nav v-if="!isAdMode" class="am-header__navigation">
          <ul role="menu">
            <div class="am-header__navigation-close">
              <div @click="mobileNavOpen = false">
                <amui-icon name="close"></amui-icon>
              </div>
            </div>
            <li v-for="(navItem, index) in navigation" :key="index" role="none">
              <a
                :href="navItem.href"
                :target="navItem.target"
                :rel="
                  typeof navItem.target === 'string' &&
                  navItem.target.toLowerCase() === '_blank'
                    ? 'noopener'
                    : null
                "
                class="am-header__navigation-item"
                :class="
                  navItem.active ? 'am-header__navigation-item--active' : ''
                "
                role="menuitem"
                @click="e => onClickMenuItem(e, navItem)"
              >
                <amui-icon
                  :class="!navItem.icon ? 'slide-right' : ''"
                  :name="navItem.icon || 'arrow-slide-right'"
                  size="sm"
                ></amui-icon>
                {{ navItem.text }}
                <amui-badge
                  :label="navItem.badge"
                  class="am-header__badge"
                  v-if="navItem.badge"
                />
              </a>
            </li>
          </ul>
        </nav>
        <div class="am-header__content">
          <div
            v-if="!isAdMode"
            class="am-header__burger-nav"
            @click="mobileNavOpen = !mobileNavOpen"
          >
            <amui-badge
              :label="navItemWithBadgeCount.badge"
              class="am-header__badge"
              v-if="navItemWithBadgeCount"
            />
            <span></span>
          </div>
          <a
            v-if="homeLink"
            :href="homeLink.href"
            :title="homeLink.text"
            rel="noopener"
            target="_blank"
            class="am-header__logo-link"
          >
            <img
              v-if="logoSource"
              :src="logoSource"
              @click="$router.push({ name: 'app' })"
              class="am-header__logo"
            />
            <span v-else class="am-header__title">{{ title }}</span>
          </a>
          <template v-else>
            <img
              v-if="logoSource"
              :src="logoSource"
              @click="$router.push({ name: 'app' })"
              class="am-header__logo"
            />
            <span v-else class="am-header__title">{{ title }}</span>
          </template>
        </div>
      </div>
      <div class="am-header__brands">
        <img
          v-for="(brand, index) in brandSources"
          :key="index"
          :src="brand.src"
          :alt="brand.alt"
          class="am-header__brand"
          :loading="index === 0 ? 'auto' : 'lazy'"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiBadge } from '@/../ui/components/badge'

export default {
  name: 'Header',

  components: {
    AmuiIcon,
    AmuiBadge
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    logoSource: {
      type: String,
      required: false,
      default: null
    },
    brandSources: {
      type: Array,
      required: false,
      default: () => []
    },
    navigation: {
      type: Array,
      required: false,
      default: () => []
    },
    homeLink: {
      type: Object,
      required: false,
      default: null
    },
    favoriteVehiclesCount: {
      type: Number,
      required: false,
      default: null
    },
    isAdMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      mobileNavOpen: false,
      preventTransitions: false
    }
  },

  computed: {
    navItemWithBadgeCount() {
      return this.navigation.find(nav => nav.badge !== null)
    }
  },

  watch: {
    mobileNavOpen: function() {
      this.mobileNavOpen
        ? (document.body.style.overflow = 'hidden')
        : document.body.style.removeProperty('overflow')
    },
    $route: {
      handler() {
        this.mobileNavOpen = false
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    onClickMenuItem(e, navItem) {
      if (navItem.route) {
        e.preventDefault()
        this.mobileNavOpen = false
        this.$emit('change-route', navItem.route)
      }
    },

    onClickNavigationBackground() {
      this.mobileNavOpen = false
    },

    handleResize() {
      this.preventTransitions = true
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.preventTransitions = false
      }, 300)
    }
  }
}
</script>
