import {
  REMOVE_VEHICLE_BY_ID,
  ADD_VEHICLE,
  SET_RAW_VEHICLE_DATA
} from './mutation-types'

export default {
  [ADD_VEHICLE]: (state, vehicle) => {
    const foundVehicle =
      state.vehicles.find(v => v.id === vehicle.id) !== undefined
    if (!foundVehicle) {
      state.vehicles.push(vehicle)
    }
  },
  [REMOVE_VEHICLE_BY_ID]: (state, vehicleId) => {
    state.vehicles = state.vehicles.filter(vehicle => vehicle.id !== vehicleId)
  },
  [SET_RAW_VEHICLE_DATA]: (state, rawVehicleData) => {
    state.rawVehicleData = rawVehicleData
  }
}
