import {
  getCheapestFinancingOffer,
  getRate as getFinancingRate
} from './financing'

import { getCheapestLeasingOffer, getRate as getLeasingRate } from './leasing'

/**
 * data for the fixed price offer
 * @param {*} priceData
 */
export const getOffer = priceData => {
  return priceData
}

/**
 *
 * leasingData and financingData needs to be normalized data
 * this method returns an offer of a list of leasing and financing offers
 * 1. check for cheapest leasing offer
 * 2. check for cheapest finance offer
 * 3. return null, no offer found
 * @param {*} leasingData
 * @param {*} financingData
 * @param {*} isCommercial
 * @returns
 */
export const getFinancingOffer = (leasingData, financingData, isCommercial) => {
  let offer = null

  if (leasingData !== null) {
    const cheapestLeasingOffer = getCheapestLeasingOffer(
      leasingData.rates,
      isCommercial
    )

    if (cheapestLeasingOffer !== null) {
      offer = {
        type: 'leasing',
        data: {
          vatRate: leasingData.vatRate,
          grossListPrice: leasingData.grossListPrice,
          lender: leasingData.lender,
          comment: leasingData.comment,
          rate: {
            modified: {},
            origin: cheapestLeasingOffer
          }
        }
      }
    }
  }

  if (offer === null && financingData !== null) {
    const cheapestFinancingOffer = getCheapestFinancingOffer(
      financingData.rates
    )

    if (cheapestFinancingOffer !== null) {
      offer = {
        type: 'financing',
        data: {
          vatRate: financingData.vatRate,
          rate: {
            modified: {},
            origin: cheapestFinancingOffer
          }
        }
      }
    }
  }

  return offer
}

/**
 * this method returns the monthly rate information of an offer
 */
export const getFinancingOfferRate = offer => {
  let rate = null

  if (offer !== null) {
    rate =
      offer.type === 'leasing' ? getLeasingRate(offer) : getFinancingRate(offer)
  }

  return rate
}

export default {
  getOffer,
  getFinancingOffer,
  getFinancingOfferRate
}
