<template>
  <button class="am-userlike-chat-button" :aria-label="ariaLabel">
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none">
      <path
        d="M37.4 30.7c-.1-.5 0-.9.2-1.4 1.5-2.8 2.3-6 2.3-9.4C40 9 31 0 20 0 9 0 0 9 0 20s9 20 20 20c3.3 0 6.5-.9 9.4-2.3.4-.2.9-.3 1.4-.2l6.8 1.3c.7.1 1.3-.5 1.2-1.2l-1.4-6.9zM21 29h-8c-1.1 0-2-.9-2-2s.9-2 2-2h8c1.1 0 2 .9 2 2s-.9 2-2 2zm6-7H13c-1.1 0-2-.9-2-2s.9-2 2-2h14c1.1 0 2 .9 2 2s-.9 2-2 2zm0-7H13c-1.1 0-2-.9-2-2s.9-2 2-2h14c1.1 0 2 .9 2 2s-.9 2-2 2z"
        fill="currentColor"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'UserlikeChatButton',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    ariaLabel: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
