<template>
  <div class="amui-modal" v-if="value">
    <div class="amui-modal__overlay" @click="close"></div>
    <div class="amui-modal__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmuiModal',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Boolean,
      require: true
    }
  },

  watch: {
    value() {
      if (this.value) {
        document.body.style = 'overflow-y: hidden;'
      } else {
        document.body.style = 'overflow-y: unset;'
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
