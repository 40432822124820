import Vue from 'vue'
import VueI18n from 'vue-i18n'
import mainMessaages from '@/app/i18n/messages/de_DE/main.json'
import tracking from '@/app/i18n/messages/de_DE/tracking.json'
import vehicleDetailPage from '@/app/i18n/messages/de_DE/vehicle-detail-page.json'
import vehicleTileExtended from '@/app/i18n/messages/de_DE/vehicle-tile-extended.json'
import searchHybrid from '@/app/i18n/messages/de_DE/search-hybrid.json'
import checkout from '@/app/i18n/messages/de_DE/checkout.json'
import mappings from '@/app/i18n/mappings/de_DE.json'
import uiMessages from '@/../ui/i18n/messages/de_DE'

Vue.use(VueI18n)

/**
 *
 * TODO need to read out the browser locale
 * load messages dynamically
 * need to work with SSR
 *
 */
export function createI18n() {
  return new VueI18n({
    locale: 'de_DE',
    fallbackLocale: 'de_DE',
    messages: {
      de_DE: Object.assign(
        mainMessaages,
        vehicleDetailPage,
        vehicleTileExtended,
        searchHybrid,
        checkout,
        mappings,
        uiMessages,
        tracking
      )
    }
  })
}
