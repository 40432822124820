import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export function createRouter(config) {
  return new VueRouter({
    mode: config.mode || 'history',
    base: config.basePath,
    routes: config.routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        if (to.path !== from.path) {
          // TODO: go to top of widget container instead of 0
          return { x: 0, y: 0 }
        } else {
          return {}
        }
      }
      /*
       TBD: hashing
       if (to.hash) {
       return {
       selector: to.hash,
       behavior: 'smooth',
       }
       }
       */
    }
  })
}
