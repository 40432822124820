import {
  SET_FIRST_UTM_TOUCHPOINT,
  SET_LAST_UTM_TOUCHPOINT
} from './mutation-types'

export const setFirstUtmTouchpoint = ({ commit }, utm) => {
  commit(SET_FIRST_UTM_TOUCHPOINT, utm)
}

export const setLastUtmTouchpoint = ({ commit }, utm) => {
  commit(SET_LAST_UTM_TOUCHPOINT, utm)
}

export default {
  setFirstUtmTouchpoint,
  setLastUtmTouchpoint
}
