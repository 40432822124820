import Vue from 'vue'
import initialState from './initial-state'

import {
  SET_RAW_VEHICLE_DATA,
  SET_PAGINATION_DATA,
  SET_PAGE,
  APPEND_RAW_VEHICLE_DATA,
  RESET_STATE
} from './mutation-types'

export default {
  [SET_RAW_VEHICLE_DATA]: (state, data) => {
    Vue.set(state, 'rawVehicleData', data)
  },
  [SET_PAGINATION_DATA]: (state, data) => {
    Vue.set(state, 'paginationData', data)
  },
  [SET_PAGE]: (state, page) => {
    Vue.set(state.paginationData, 'page', page)
  },
  [APPEND_RAW_VEHICLE_DATA]: (state, data) => {
    if (Array.isArray(state.rawVehicleData)) {
      Vue.set(state, 'rawVehicleData', state.rawVehicleData.concat(data))
    }
  },
  [RESET_STATE]: state => {
    Object.assign(state, initialState())
  }
}
