import { convertToFloat, convertToCents } from './../utils/converter'

/**
 * this method will process the incoming mobile data format in a way that it is better useable in our application.
 * it parse all numbers given as strings, to real numbers (integers, floats)
 * - convert all prices to cents
 * - convert percent values to floats
 * - convert numbers as strings to real integers (mileage, termOfContract)
 *
 * expected input format: https://services.mobile.de/docs/seller-api.html#Leasing_Schema
 *
 * @param {*} data
 * @returns noramlized data
 */
export const getNormalizedLeasingData = data => {
  return Object.assign({}, data, {
    grossListPrice: convertToCents(data.grossListPrice),
    vatRate:
      data.vatRate !== undefined ? convertToFloat(data.vatRate) : undefined,
    rates: data.rates.map(rate => {
      let modifiedRate = Object.assign({}, rate, {
        annualMileage: parseInt(rate.annualMileage),
        downPayment: convertToCents(rate.downPayment),
        totalAmount: convertToCents(rate.totalAmount),
        termOfContract: parseInt(rate.termOfContract)
      })

      if (
        rate.annualPercentageRate !== undefined &&
        rate.annualPercentageRate !== -1
      ) {
        modifiedRate.annualPercentageRate = convertToFloat(
          rate.annualPercentageRate
        )
      } else {
        delete modifiedRate.annualPercentageRate
      }

      if (
        rate.nominalInterestRate !== undefined &&
        rate.nominalInterestRate !== -1
      ) {
        modifiedRate.nominalInterestRate = convertToFloat(
          rate.nominalInterestRate
        )
      } else {
        delete modifiedRate.nominalInterestRate
      }

      if (rate.termOfContract !== undefined) {
        modifiedRate.termOfContract = parseInt(rate.termOfContract)
      }

      if (rate.netLoanAmount !== undefined) {
        modifiedRate.netLoanAmount = convertToCents(rate.netLoanAmount)
      }

      if (rate.destinationCharges !== undefined) {
        modifiedRate.destinationCharges = convertToCents(
          rate.destinationCharges
        )
      }

      if (rate.registrationFees !== undefined) {
        modifiedRate.registrationFees = convertToCents(rate.registrationFees)
      }

      if (rate.extraMileageCosts !== undefined) {
        modifiedRate.extraMileageCosts = convertToCents(rate.extraMileageCosts)
      }

      if (rate.lowMileageCompensation !== undefined) {
        modifiedRate.lowMileageCompensation = convertToCents(
          rate.lowMileageCompensation
        )
      }

      if (rate.grossRate !== undefined) {
        modifiedRate.grossRate = convertToCents(rate.grossRate)
      }

      if (rate.netRate !== undefined) {
        modifiedRate.netRate = convertToCents(rate.netRate)
      }

      return modifiedRate
    })
  })
}

/**
 * this method will determine and return the cheapest offer in a offer matrix depending on the given commercial state
 * note: the leasingMatrix data should be normalized before passing to this method. never pass in the raw mobile ad leasing object.
 *
 * @param {*} leasingMatrix
 * @param {*} isCommercial
 * @returns leasing rate object
 */
export const getCheapestLeasingOffer = (leasingMatrix, isCommercial) => {
  const ratesBySelectedLeasingType = leasingMatrix.filter(
    rate =>
      rate.type.toLowerCase() === (isCommercial ? 'commercial' : 'private')
  )

  let rates = [...ratesBySelectedLeasingType]

  const sortedByMonthlyRate = rates.sort((a, b) => {
    if (isCommercial) {
      return a.netRate > b.netRate ? 1 : -1
    } else {
      return a.grossRate > b.grossRate ? 1 : -1
    }
  })

  return sortedByMonthlyRate.length > 0 ? sortedByMonthlyRate[0] : null
}

export const getRate = offer => {
  let rate = null

  if (offer !== null) {
    const rateData = Object.assign(
      {},
      offer.data.rate.origin,
      offer.data.rate.modified
    )

    rate = {
      type: offer.type,
      grossRate: rateData.grossRate,
      netRate: rateData.netRate
    }

    // `grossRate` is missing for leasing type `commercial`
    if (
      rate.grossRate === undefined &&
      rate.netRate !== undefined &&
      offer.data.vatRate !== undefined
    ) {
      rate.grossRate = rate.netRate * (1 + offer.data.vatRate / 100)
    }

    // `netRate` is missing for leasing type `private`
    if (
      rate.netRate === undefined &&
      rate.grossRate !== undefined &&
      offer.data.vatRate !== undefined
    ) {
      rate.netRate = rate.grossRate / (1 + offer.data.vatRate / 100)
    }
  }

  return rate
}

export default {
  getNormalizedLeasingData,
  getCheapestLeasingOffer,
  getRate
}
